body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  font-family:
          "Trebuchet MS","Helvetica","Arial",Tahoma,sans-serif;
  font-size: 15px;
  line-height: 22px;
  overflow-x: hidden;
}

body.mobile-modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root > main {
  flex: 1 0 auto;
}

#root > footer {
  flex-shrink: 0;
}

/*typography*/
h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: initial;
}
h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: initial;
}
h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: initial;
}
h4 {
  font-size: 22px;
  font-weight: 700;
  line-height: initial;
}
h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: initial;
}
h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: initial;
}
